.site-layout-content {
    min-height: 280px;
    padding: 5px 24px 24px 24px;
}
#components-layout-demo-top .logo {
    float: left;
    width: 120px;
    height: 31px;
    margin: 16px 24px 16px 0;
    background: rgba(255, 255, 255, 0.3);
}
.ant-row-rtl #components-layout-demo-top .logo {
    float: right;
    margin: 16px 0 16px 24px;
}

@media only screen and (min-width: 950px) {
    main {
        padding: 20px 50px;
    }
}

@media only screen and (max-width: 950px) {
    header {
        padding: 0 10px !important;
    }
}

body {
    margin: 0 !important;
}
